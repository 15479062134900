import Experience from '../Experience.js';

import Can from './Can.js';
import Ground from './Ground.js';
import Background from './Background.js';

import NormalizeWheel from 'normalize-wheel';
import Controls from './Controls.js';
import SnowParticles from './SnowParticles.js';
import Mountain from './Mountain.js';
import Marquee from './Marquee.js';

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    // Wait for resources
    this.resources.on('ready', () => {
      // Setup
      this.controls = new Controls();
      this.background = new Background();
      this.snowParticles = new SnowParticles();
      this.can = new Can();
      this.ground = new Ground();
      this.marquee = new Marquee();

      this.mountain = new Mountain();

      this.addEventListeners();
    });
  }

  update() {
    if (this.can) this.can.update();
    if (this.controls) this.controls.update();
    if (this.snowParticles) this.snowParticles.update();
    if (this.marquee) this.marquee.update();
  }
  /***
   * Listeners.
   */
  addEventListeners() {
    document.addEventListener('wheel', this.onWheel.bind(this));
    document.addEventListener('mousemove', this.onMouseMove.bind(this));
    document.addEventListener('touchstart', this.onTouchDown.bind(this));
    document.addEventListener('touchmove', this.onTouchMove.bind(this));
    document.addEventListener('touchend', this.onTouchUp.bind(this));
  }

  onWheel(event) {
    const normalizedWheel = NormalizeWheel(event);

    if (this.controls && this.controls.onWheel) {
      this.controls.onWheel(normalizedWheel);
    }
  }

  onMouseMove(event) {
    if (this.controls && this.controls.onMouseMove) {
      this.controls.onMouseMove(event);
    }
  }
  onTouchDown(event) {
    if (this.controls && this.controls.onTouchDown) {
      this.controls.onTouchDown(event);
    }
  }
  onTouchMove(event) {
    if (this.controls && this.controls.onTouchMove) {
      this.controls.onTouchMove(event);
    }
  }
  onTouchUp(event) {
    if (this.controls && this.controls.onTouchUp) {
      this.controls.onTouchUp(event);
    }
  }
}
