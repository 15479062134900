import * as THREE from 'three';
import Experience from '../Experience.js';

export default class Can {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.camera = this.experience.camera.instance;
    this.renderer = this.experience.renderer.instance;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;
    this.dummy = new THREE.Object3D();
    this.canInstancePositions = [];

    this.objectIndex = 0;
    //Resources
    this.canScene = this.resources.items.can;
    this.canTexture1 = this.resources.items.can_texture1;
    this.canTexture1.flipY = false;
    this.canTexture1.encoding = THREE.sRGBEncoding;
    this.canTexture2 = this.resources.items.can_texture2;
    this.canTexture2.flipY = false;
    this.canTexture2.encoding = THREE.sRGBEncoding;
    this.canTexture3 = this.resources.items.can_texture3;
    this.canTexture3.flipY = false;
    this.canTexture3.encoding = THREE.sRGBEncoding;
    this.canTexture4 = this.resources.items.can_texture4;
    this.canTexture4.flipY = false;
    this.canTexture4.encoding = THREE.sRGBEncoding;
    this.canTexture5 = this.resources.items.can_texture5;
    this.canTexture5.flipY = false;
    this.canTexture5.encoding = THREE.sRGBEncoding;
    this.canTexture6 = this.resources.items.can_texture6;
    this.canTexture6.flipY = false;
    this.canTexture6.encoding = THREE.sRGBEncoding;
    this.canTexture7 = this.resources.items.can_texture7;
    this.canTexture7.flipY = false;
    this.canTexture7.encoding = THREE.sRGBEncoding;
    this.canTexture8 = this.resources.items.can_texture8;
    this.canTexture8.flipY = false;
    this.canTexture8.encoding = THREE.sRGBEncoding;
    this.canTexture9 = this.resources.items.can_texture9;
    this.canTexture9.flipY = false;
    this.canTexture9.encoding = THREE.sRGBEncoding;
    this.canTexture10 = this.resources.items.can_texture10;
    this.canTexture10.flipY = false;
    this.canTexture10.encoding = THREE.sRGBEncoding;
    this.canTexture11 = this.resources.items.can_texture11;
    this.canTexture11.flipY = false;
    this.canTexture11.encoding = THREE.sRGBEncoding;
    this.canTexture12 = this.resources.items.can_texture12;
    this.canTexture12.flipY = false;
    this.canTexture12.encoding = THREE.sRGBEncoding;

    this.canMatcap = this.resources.items.matcap;
    if (this.debug.active) {
      this.folder = this.debug.ui.addFolder('Ground');
    }

    //Objects
    this.canBodyMeshes = [];
    this.canTBMeshes = [];

    this.createCan();
  }

  createCan() {
    this.assets = [
      this.canTexture1,
      this.canTexture2,
      this.canTexture3,
      this.canTexture4,
      this.canTexture5,
      this.canTexture6,
      this.canTexture7,
      this.canTexture8,
      this.canTexture9,
      this.canTexture10,
      this.canTexture11,
      this.canTexture12,
    ];

    this.canBody = this.canScene.scene.children.find(
      (child) => child.name === 'Can_Body'
    );
    this.canTB = this.canScene.scene.children.find(
      (child) => child.name === 'Can_TB'
    );
    this.canTB.material = new THREE.MeshMatcapMaterial({
      matcap: this.canMatcap,
    });
    //Remove later
    this.canTB.material.onBeforeCompile = (shader) => {
      //Uniforms
      shader.uniforms.textures = {
        value: [
          new THREE.Color('#848482'),
          new THREE.Color('#848482'),
          new THREE.Color('#848482'),
          new THREE.Color('#848482'),
          new THREE.Color('#ab862b'),
          new THREE.Color('#61A0B1'),
          new THREE.Color('#848482'),
          new THREE.Color('#848482'),
          new THREE.Color('#848482'),
          new THREE.Color('#848482'),
          new THREE.Color('#848482'),
          new THREE.Color('#ab862b'),
        ],
      };

      //Vertex shader
      shader.vertexShader = shader.vertexShader.replace(
        'varying vec3 vViewPosition;',
        `
            varying vec3 vViewPosition;
            attribute float textureIndex;
            varying float vTextureIndex;
            uniform float uTime;
      
    
          `
      );
      shader.vertexShader = shader.vertexShader.replace(
        '#include <project_vertex>',
        `
 
            vec4 mvPosition = vec4( transformed, 1.0 );
    
            #ifdef USE_INSTANCING
            
              mvPosition = instanceMatrix * mvPosition;
            
            #endif
            
            mvPosition = modelViewMatrix * mvPosition;
            
            gl_Position = projectionMatrix * mvPosition;
          `
      );
      shader.vertexShader = shader.vertexShader.replace(
        'vViewPosition = - mvPosition.xyz;',
        `
            vViewPosition = - mvPosition.xyz;
            vTextureIndex=textureIndex;
          `
      );
      //Fragment shader
      shader.fragmentShader = shader.fragmentShader.replace(
        'varying vec3 vViewPosition;',
        `
            varying vec3 vViewPosition;
            varying float vTextureIndex;
            uniform vec3 textures[12];
          `
      );
      shader.fragmentShader = shader.fragmentShader.replace(
        'vec4 diffuseColor = vec4( diffuse, opacity );',
        `
            float texId = vTextureIndex;
            vec3 col;
    
    col = textures[0] * step(-0.1, texId) * step(texId, 0.1);
    col +=textures[1] * step(0.9, texId) * step(texId, 1.1);
    col +=textures[2] * step(1.9, texId) * step(texId, 2.1);
    col +=textures[3] * step(2.9, texId) * step(texId, 3.1);
    col +=textures[4] * step(3.9, texId) * step(texId, 4.1);
    col +=textures[5] * step(4.9, texId) * step(texId, 5.1);
    col +=textures[6] * step(5.9, texId) * step(texId, 6.1);
    col +=textures[7] * step(6.9, texId) * step(texId, 7.1);
    col +=textures[8] * step(7.9, texId) * step(texId, 8.1);
    col +=textures[9] * step(8.9, texId) * step(texId, 9.1);
    col += textures[10] * step(9.9, texId) * step(texId, 10.1);
    col += textures[11] * step(10.9, texId) * step(texId, 11.1);
 

  
  vec4 diffuseColor = vec4(col, opacity );
                                    
          `
      );
    };
    this.canTB.geometry.setAttribute(
      'textureIndex',
      new THREE.InstancedBufferAttribute(
        new Float32Array([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
        1
      )
    );
    this.canScene.scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        this.material = new THREE.MeshMatcapMaterial({
          matcap: this.canMatcap,
        });
        if (child.name.includes('Can_Body')) {
          this.material.map = this.assets[this.objectIndex];
          this.objectIndex = this.objectIndex + 1;

          child.material = this.material;
          child.material.needsUpdate = true;
          this.canBodyMeshes.push(child);
        }
      }
    });
    this.canScene.scene.children.map((child) => {
      this.canInstancePositions.push(child.position);
    });
    this.canBodyMeshes.forEach((child) => {
      this.scene.add(child);
    });
    this.meshCTB = new THREE.InstancedMesh(
      this.canTB.geometry,
      this.canTB.material,
      12
    );
    this.scene.add(this.meshCTB);
    this.setInstancedMeshPositions(this.meshCTB);
  }
  setInstancedMeshPositions(mesh) {
    for (var i = 0; i < mesh.count; i++) {
      let { x, y, z } = this.canInstancePositions[i];

      this.dummy.position.set(x, y, z);
      // this.dummy.rotation.y += 0.005;
      this.dummy.updateMatrix();
      mesh.setMatrixAt(i, this.dummy.matrix);
    }
    mesh.instanceMatrix.needsUpdate = true;
  }
  update() {
    // this.setInstancedMeshPositions(this.meshCTB);
    // this.canBodyMeshes.forEach((child) => {
    //   child.rotation.y += 0.005;
    // });
  }
}
