export default [
  //Snowflake
  {
    name: 'snowflakeTexture',
    type: 'texture',
    path: 'textures/snowflake.png',
  },
  //Mountain texture
  {
    name: 'mountainDiffuseTexture',
    type: 'texture',
    path: 'textures/ground/m_baked.jpg',
  },
  //Ground texture marqueeDiffuseTexture
  {
    name: 'groundDiffuseTexture',
    type: 'texture',
    path: 'textures/ground/snow_diff.jpg',
  },
  {
    name: 'groundBaked',
    type: 'texture',
    path: 'textures/ground/ground_shadow_baked.jpg',
  },

  {
    name: 'groundSparkleTexture',
    type: 'texture',
    path: 'textures/ground/snow_sparkle.png',
  },
  //Marquee texture

  {
    name: 'texture1',
    type: 'texture',
    path: 'textures/marquee/texture1.jpg',
  },
  {
    name: 'texture2',
    type: 'texture',
    path: 'textures/marquee/texture2.jpg',
  },
  {
    name: 'texture_tb1',
    type: 'texture',
    path: 'textures/marquee/12days.png',
  },
  {
    name: 'texture_tb2',
    type: 'texture',
    path: 'textures/marquee/friends.png',
  },
  //Models

  {
    name: 'can',
    type: 'gltfModel',
    path: 'models/can3.glb',
  },

  {
    name: 'ground',
    type: 'gltfModel',
    path: 'models/terrain.glb',
  },
  {
    name: 'mountain',
    type: 'gltfModel',
    path: 'models/mt2.glb',
  },
  {
    name: 'marquee1',
    type: 'gltfModel',
    path: 'models/main_text1.glb',
  },
  {
    name: 'marquee2',
    type: 'gltfModel',
    path: 'models/main_text2.glb',
  },
  {
    name: 'plane1',
    type: 'gltfModel',
    path: 'models/plane1.glb',
  },
  {
    name: 'plane2',
    type: 'gltfModel',
    path: 'models/plane2.glb',
  },

  //Can matcap
  {
    name: 'matcap',
    type: 'texture',
    path: 'textures/can/matcap.png',
  },

  //Can textures
  {
    name: 'can_texture1',
    type: 'texture',
    path: 'textures/can/1.jpg',
  },
  {
    name: 'can_texture2',
    type: 'video',
    path: 'textures/can/2.mp4',
  },
  {
    name: 'can_texture3',
    type: 'texture',
    path: 'textures/can/3.jpg',
  },
  {
    name: 'can_texture4',
    type: 'texture',
    path: 'textures/can/4.jpg',
  },
  {
    name: 'can_texture5',
    type: 'texture',
    path: 'textures/can/5.jpg',
  },
  {
    name: 'can_texture6',
    type: 'texture',
    path: 'textures/can/6.jpg',
  },
  {
    name: 'can_texture7',
    type: 'texture',
    path: 'textures/can/7.jpg',
  },
  {
    name: 'can_texture8',
    type: 'texture',
    path: 'textures/can/8.jpg',
  },
  {
    name: 'can_texture9',
    type: 'texture',
    path: 'textures/can/9.jpg',
  },
  {
    name: 'can_texture10',
    type: 'texture',
    path: 'textures/can/10.jpg',
  },
  {
    name: 'can_texture11',
    type: 'video',
    path: 'textures/can/11.mp4',
  },
  {
    name: 'can_texture12',
    type: 'video',
    path: 'textures/can/12.mp4',
  },
];
