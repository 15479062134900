import * as THREE from 'three';
import Experience from './Experience.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export default class Camera {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;

    // Group
    this.cameraGroup = new THREE.Group();
    this.scene.add(this.cameraGroup);

    this.setInstance();
    //this.setControls();
  }

  setInstance() {
    this.instance = new THREE.PerspectiveCamera(
      85,
      this.sizes.width / this.sizes.height,
      0.1,
      3000
    );
    this.instance.position.set(0, 2, 0);
    this.cameraGroup.add(this.instance);
  }

  setControls() {
    this.controls = new OrbitControls(this.instance, this.canvas);
    this.controls.enableDamping = true;
  }

  resize() {
    this.instance.aspect = this.sizes.width / this.sizes.height;
    this.instance.updateProjectionMatrix();
  }

  update() {
    //this.controls.update();
  }
}
