import * as THREE from 'three';
import Experience from '../Experience';
import vertexShader from '../Shaders/SnowParticles/vertex.glsl';
import fragmentShader from '../Shaders/SnowParticles/fragment.glsl';

export default class SnowParticles {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.createMaterial();
    this.createGeometry();
    this.createMesh();
  }

  createMaterial() {
    this.material = new THREE.ShaderMaterial({
      transparent: true,
      alphaTest: 0.001,
      // blending: THREE.AdditiveBlending,

      // blending: THREE.AdditiveBlending,
      // vertexColors: true,
      uniforms: {
        uSize: {
          value: 300 * this.experience.renderer.instance.getPixelRatio(),
        },
        uColor: { value: new THREE.Color() },
        uAlpha: { value: 1 },
        uTime: { value: 0 },
        uTexture: { value: this.resources.items.snowflakeTexture },
        uSwitch: { value: 1 },
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });
  }
  createGeometry() {
    this.geometry = new THREE.BufferGeometry();
    const count = 20000;
    const positions = new Float32Array(count * 3);
    const rotation = new Float32Array(count * 3);
    const scales = new Float32Array(count);
    const randomness = new Float32Array(count * 3);

    for (let i = 0; i < count * 3; i++) {
      const three = i * 3;

      positions[three] = (Math.random() - 0.5) * 320;
      positions[three + 1] = (Math.random() - 0.5) * 320;
      positions[three + 2] = (Math.random() - 0.7) * 320;

      //Scale
      scales[i] = Math.random();

      //Rotation
      rotation[three] = Math.random() * 2 * Math.PI;
      rotation[three + 1] = Math.random() * 20;
      rotation[three + 2] = Math.random() * 10;
    }

    this.geometry.setAttribute(
      'position',
      new THREE.BufferAttribute(positions, 3)
    );
    this.geometry.setAttribute(
      'aRotation',
      new THREE.BufferAttribute(rotation, 3)
    );

    this.geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1));
    this.geometry.setAttribute(
      'aRandomness',
      new THREE.BufferAttribute(randomness, 3)
    );
  }
  createMesh() {
    this.points = new THREE.Points(this.geometry, this.material);

    this.scene.add(this.points);
  }

  onResize({ screen, viewport } = {}) {
    if (screen) {
      this.screen = screen;
    }

    if (viewport) {
      this.viewport = viewport;
    }

    this.scale = this.experience.sizes.height / 1500;
  }
  update(scroll, direction) {
    this.material.uniforms.uTime.value += 0.2;
  }
}
