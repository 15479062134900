import * as THREE from 'three';
import Experience from '../Experience.js';
import vertex from '../Shaders/Background/vertex.glsl';
import fragment from '../Shaders/Background/fragment.glsl';

export default class Can {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.sizes = this.experience.sizes;
    this.camera = this.experience.camera.instance;
    this.renderer = this.experience.renderer.instance;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    if (this.debug.active) {
      this.folder = this.debug.ui.addFolder('Background');
    }

    this.createBackground();
  }

  createBackground() {
    this.geometry = new THREE.SphereGeometry(3000, 500);
    this.material = new THREE.ShaderMaterial({
      uniforms: {
        uResolution: {
          value: new THREE.Vector2(this.sizes.width, this.sizes.height),
        },
      },
      side: THREE.DoubleSide,
      vertexShader: vertex,
      fragmentShader: fragment,
    });
    this.mesh = new THREE.Mesh(this.geometry, this.material);

    this.scene.add(this.mesh);
  }

  update() {}
}
