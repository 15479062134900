import * as THREE from 'three';
import Experience from '../Experience.js';
import vertex from '../Shaders/Marquee/vertex.glsl';
import fragment from '../Shaders/Marquee/fragment.glsl';

export default class Marquee {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.camera = this.experience.camera.instance;
    this.renderer = this.experience.renderer.instance;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.sizes = this.experience.sizes;
    this.debug = this.experience.debug;

    //Resources
    this.marqueeScene1 = this.resources.items.marquee1;
    this.marqueeScene2 = this.resources.items.marquee2;
    this.planeScene1 = this.resources.items.plane1;
    this.planeScene2 = this.resources.items.plane2;

    this.textureTB1 = this.resources.items.texture_tb1;
    this.textureTB1.flipY = false;
    this.textureTB2 = this.resources.items.texture_tb2;
    this.textureTB2.flipY = false;
    this.matcap = this.resources.items.matcap;
    this.diffuse1 = this.resources.items.texture1;
    this.diffuse1.wrapS = THREE.RepeatWrapping;
    this.diffuse1.wrapT = THREE.RepeatWrapping;
    this.diffuse1.magFilter = THREE.NearestFilter;
    this.diffuse1.minFilter = THREE.NearestFilter;
    this.diffuse1.encoding = THREE.sRGBEncoding;
    this.diffuse2 = this.resources.items.texture2;
    this.diffuse2.wrapS = THREE.RepeatWrapping;
    this.diffuse2.wrapT = THREE.RepeatWrapping;
    this.diffuse2.magFilter = THREE.NearestFilter;
    this.diffuse2.minFilter = THREE.NearestFilter;
    this.diffuse2.encoding = THREE.sRGBEncoding;
    this.diffuse2.repeat.set(10, 10);
    this.diffuse1.repeat.set(3, 3);

    this.matcap.encoding = THREE.sRGBEncoding;
    if (this.debug.active) {
      this.folder = this.debug.ui.addFolder('Marquee');
    }

    this.createMarquee();
    this.createBackground();
  }
  createMarquee() {
    this.marquee1 = this.marqueeScene1.scene.children.find(
      (child) => child.name === 'main_text1'
    );
    this.marquee2 = this.marqueeScene2.scene.children.find(
      (child) => child.name === 'main_text2'
    );
    this.plane1 = this.planeScene1.scene.children.find(
      (child) => child.name === 'plane1'
    );
    this.plane2 = this.planeScene2.scene.children.find(
      (child) => child.name === 'plane2'
    );

    this.marquee1.material = new THREE.MeshMatcapMaterial({
      map: this.diffuse2,
      matcap: this.matcap,
    });
    this.marquee2.material = new THREE.MeshMatcapMaterial({
      map: this.diffuse1,
      matcap: this.matcap,
    });

    this.scene.add(this.marquee1);
    this.scene.add(this.marquee2);

    if (this.debug.active) {
      // this.folder
      //   .add(this.floor.material, 'roughness')
      //   .min(0)
      //   .max(10)
      //   .step(0.001)
      //   .name('roughness');
    }
  }
  createBackground() {
    //  this.geo1 = new THREE.PlaneGeometry(8, 8);

    this.plane1.material = new THREE.MeshBasicMaterial({
      transparent: true,
      map: this.textureTB1,
    });
    this.plane2.material = new THREE.MeshBasicMaterial({
      transparent: true,
      map: this.textureTB2,
    });
    // this.plane1 = new THREE.Mesh(this.geo1, this.mat1);
    // this.plane1.position.z = -133.8;
    // this.plane1.position.y = 5.7;

    // this.geo2 = new THREE.PlaneGeometry(8, 8);

    // this.mat2 = new THREE.MeshBasicMaterial({
    //   transparent: true,
    //   map: this.textureTB2,
    // });
    // this.plane2 = new THREE.Mesh(this.geo2, this.mat2);
    // this.plane2.position.z = -133.7;
    // this.plane2.position.y = 2.2;
    // this.plane2.frustumCulled = false;
    this.scene.add(this.plane1);
    this.scene.add(this.plane2);
  }

  update() {}
}
