import * as THREE from 'three';
import Experience from '../Experience.js';
import vertex from '../Shaders/Mountain/vertex.glsl';
import fragment from '../Shaders/Mountain/fragment.glsl';

export default class Mountain {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.camera = this.experience.camera.instance;
    this.renderer = this.experience.renderer.instance;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.sizes = this.experience.sizes;
    this.debug = this.experience.debug;

    //Resources
    this.mountainScene = this.resources.items.mountain;
    this.groundBaked = this.resources.items.groundBaked;
    this.groundBaked.flipY = false;
    this.groundBaked.encoding = THREE.sRGBEncoding;
    this.mountainDiffuseTexture = this.resources.items.mountainDiffuseTexture;
    this.groundDiffuseTexture = this.resources.items.groundDiffuseTexture;
    this.groundDiffuseTexture.encoding = THREE.sRGBEncoding;

    this.mountainDiffuseTexture.encoding = THREE.sRGBEncoding;
    this.mountainDiffuseTexture.flipY = false;
    this.groundSparkleTexture = this.resources.items.groundSparkleTexture;
    this.groundSparkleTexture.wrapS = THREE.RepeatWrapping;
    this.groundSparkleTexture.wrapT = THREE.RepeatWrapping;
    this.groundSparkleTexture.magFilter = THREE.NearestFilter;
    this.groundSparkleTexture.minFilter = THREE.NearestFilter;

    this.groundDiffuseTexture.wrapS = THREE.RepeatWrapping;
    this.groundDiffuseTexture.wrapT = THREE.RepeatWrapping;
    this.groundDiffuseTexture.magFilter = THREE.NearestFilter;
    this.groundDiffuseTexture.minFilter = THREE.NearestFilter;
    if (this.debug.active) {
      this.folder = this.debug.ui.addFolder('Ground');
    }

    this.createMountain();
  }
  createMountain() {
    this.mountain = this.mountainScene.scene.children.find(
      (child) => child.name === 'Mountain'
    );

    this.mountain.material = new THREE.ShaderMaterial({
      uniforms: {
        uBakedTexture: { value: this.groundBaked },
        uSnowDiffuse: { value: this.groundDiffuseTexture },
        uRockDiffuse: { value: this.mountainDiffuseTexture },
        uSparkleTexture: { value: this.groundSparkleTexture },
        uResolution: {
          value: new THREE.Vector2(this.sizes.width, this.sizes.height),
        },
      },
      fragmentShader: fragment,
      vertexShader: vertex,
    });

    this.mesh = new THREE.Mesh(this.mountain.geometry, this.mountain.material);

    this.scene.add(this.mesh);

    if (this.debug.active) {
      // this.folder
      //   .add(this.floor.material, 'roughness')
      //   .min(0)
      //   .max(10)
      //   .step(0.001)
      //   .name('roughness');
    }
  }

  update() {}
}
