import * as THREE from 'three';
import Experience from '../Experience.js';
import GSAP from 'gsap';
export default class Controls {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.camera = this.experience.camera;
    this.renderer = this.experience.renderer.instance;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.sizes = this.experience.sizes;
    this.debug = this.experience.debug;

    this.scroll = {
      current: 0,
      target: 0,
      last: 0,
      limit: 0,
      last: 0,
    };
    this.mouse = new THREE.Vector2();
  }
  /**
   * Events.
   */
  onTouchDown(event) {
    this.isDown = true;

    this.scroll.position = this.scroll.current;

    this.start = event.touches ? event.touches[0].clientY : event.clientY;
  }

  onTouchMove(event) {
    if (!this.isDown) return;

    const y = event.touches ? event.touches[0].clientY : event.clientY;
    const distance = (this.start - y) * 0.06;

    this.scroll.target = this.scroll.position - distance;
  }

  onTouchUp(event) {
    this.isDown = false;
  }

  onWheel({ pixelY }) {
    this.scroll.target -= pixelY * 0.01;
  }
  onMouseMove(event) {
    this.mouse.x = (event.clientX / this.sizes.width) * 2 - 1;
    this.mouse.y = -(event.clientY / this.sizes.height) * 2 + 1;

    this.camera.instance.rotation.y = GSAP.utils.interpolate(
      this.camera.instance.rotation.y,
      (this.mouse.x * Math.PI) / 25,
      0.03
    );
    this.camera.instance.rotation.x = GSAP.utils.interpolate(
      this.camera.instance.rotation.x,
      (this.mouse.y * Math.PI) / 25,
      0.03
    );
  }

  update() {
    this.scroll.target = GSAP.utils.clamp(-125, 0, this.scroll.target);
    this.scroll.current = GSAP.utils.interpolate(
      this.scroll.current,
      this.scroll.target,
      0.03
    );
    if (this.scroll.current > this.scroll.last) {
      this.direction = 'backwards';
    } else {
      this.direction = 'forward';
    }

    this.camera.instance.position.z = this.scroll.current;
    if (
      this.camera.instance.position.z < -117 &&
      this.direction === 'forward'
    ) {
      this.camera.instance.position.x = GSAP.utils.interpolate(
        this.camera.instance.position.x,
        0,
        0.03
      );
    } else if (
      this.camera.instance.position.z < -117 &&
      this.direction === 'backwards'
    ) {
      this.camera.instance.position.x = GSAP.utils.interpolate(
        this.camera.instance.position.x,
        2.3,
        0.03
      );
    } else if (this.camera.instance.position.z > -117) {
      this.camera.instance.position.x = GSAP.utils.interpolate(
        this.camera.instance.position.x,
        Math.sin(this.scroll.current * (Math.PI * 0.1)) * 2.4,
        0.5
      );
    }
    this.scroll.last = this.scroll.current;
  }
}

