import * as THREE from 'three';
import Experience from '../Experience.js';
import vertex from '../Shaders/Snow/vertex.glsl';
import fragment from '../Shaders/Snow/fragment.glsl';

export default class Ground {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.camera = this.experience.camera.instance;
    this.renderer = this.experience.renderer.instance;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.sizes = this.experience.sizes;
    this.debug = this.experience.debug;
    this.dummy = new THREE.Object3D();
    this.groundInstancePositions = [];

    //Resources
    this.groundScene = this.resources.items.ground;
    this.groundBaked = this.resources.items.groundBaked;
    this.groundBaked.flipY = false;
    this.groundBaked.encoding = THREE.sRGBEncoding;
    this.groundDiffuseTexture = this.resources.items.groundDiffuseTexture;
    this.groundDiffuseTexture.encoding = THREE.sRGBEncoding;

    this.groundSparkleTexture = this.resources.items.groundSparkleTexture;
    this.groundSparkleTexture.wrapS = THREE.RepeatWrapping;
    this.groundSparkleTexture.wrapT = THREE.RepeatWrapping;
    this.groundSparkleTexture.magFilter = THREE.NearestFilter;
    this.groundSparkleTexture.minFilter = THREE.NearestFilter;
    this.groundDiffuseTexture.wrapS = THREE.RepeatWrapping;
    this.groundDiffuseTexture.wrapT = THREE.RepeatWrapping;
    this.groundDiffuseTexture.magFilter = THREE.NearestFilter;
    this.groundDiffuseTexture.minFilter = THREE.NearestFilter;
    if (this.debug.active) {
      this.folder = this.debug.ui.addFolder('Ground');
    }

    this.createGround();
  }
  createGround() {
    this.ground = this.groundScene.scene.children.find(
      (child) => child.name === 'Ground'
    );

    this.groundScene.scene.children.map((child) => {
      this.groundInstancePositions.push({
        position: child.position,
        rotation: child.rotation,
      });
    });

    this.ground.material = new THREE.ShaderMaterial({
      uniforms: {
        uBakedTexture: { value: this.groundBaked },
        uDiffuse: { value: this.groundDiffuseTexture },
        uSparkleTexture: { value: this.groundSparkleTexture },
        uResolution: {
          value: new THREE.Vector2(this.sizes.width, this.sizes.height),
        },
      },
      fragmentShader: fragment,
      vertexShader: vertex,
    });

    this.mesh = new THREE.InstancedMesh(
      this.ground.geometry,
      this.ground.material,
      1
    );
    this.mesh.receiveShadow = true;

    this.scene.add(this.mesh);

    this.setInstancedMeshPositions(this.mesh);

    if (this.debug.active) {
      // this.folder
      //   .add(this.floor.material, 'roughness')
      //   .min(0)
      //   .max(10)
      //   .step(0.001)
      //   .name('roughness');
    }
  }
  setInstancedMeshPositions(mesh) {
    for (var i = 0; i < mesh.count; i++) {
      let { position, rotation } = this.groundInstancePositions[i];

      this.dummy.position.set(position.x, position.y, position.z);
      this.dummy.rotation.set(rotation.x, rotation.y, rotation.z);
      this.dummy.updateMatrix();
      mesh.setMatrixAt(i, this.dummy.matrix);
    }
    mesh.instanceMatrix.needsUpdate = true;
  }
  update() {}
}
